import { Injectable } from '@angular/core';
import { OrgaentityLevel } from '@dep/common/core-api/types/orgaentity.type';
import { CountryCode } from '@dep/common/countries';
import { RoleRight } from '@dep/common/interfaces';
import { UserCustomLink } from '@dep/common/user-api/types/user.type';
import { NGXLogger } from 'ngx-logger';

import { OrgaentitiesService } from '@dep/frontend/services/orgaentities.service';
import { UserService } from '@dep/frontend/services/user.service';
import { environment } from 'src/environments/environment';

const stageSuffix = {
  local: '-dev',
  dev: '-dev',
  test: '-int',
  prod: '',
}[environment.stage];

type StaticLink = Omit<UserCustomLink, 'url'> & {
  // Extend `url` with function definition for dynamic URLs.
  url: UserCustomLink['url'] | ((country?: CountryCode) => string | null);
  icon: string;
  requiredRight?: RoleRight;
};

export type StaticLinkWithParsedUrl = Omit<StaticLink, 'url' | 'requiredRight'> & {
  // Extend `url` with function definition for dynamic URLs.
  url: UserCustomLink['url'] | null;
};

@Injectable({
  providedIn: 'root',
})
export class LinkoutsService {
  /** Static links definition. */
  public readonly STATIC_LINKS: StaticLink[] = [
    {
      title: 'DIGITAL_SIGNAGE',
      icon: 'digital_signage.svg',
      url: 'https://rcps4.mercedes-benz.com',
    },
    {
      title: 'WELCOME_ASSISTANT',
      icon: 'welcome_assistant.svg',
      url: 'https://app.matchmaker.i.mercedes-benz.com',
    },
    {
      title: 'HANDOVER_ASSISTANT',
      icon: 'handover_assistant.svg',
      url: 'https://handover.i.mercedes-benz.com',
    },
    {
      title: 'SHOWROOM_CONNECT',
      icon: 'showroom_connect.svg',
      url: `https://digital-elements.rcps${stageSuffix}.mercedes-benz.com/connectedshowroom`,
      requiredRight: RoleRight.CONNECTEDSHOWROOM,
    },
    {
      title: 'SHOWROOM_PRICE_TAG',
      icon: 'showroom_price_tag.svg',
      url: 'https://roomctrl.csrt.i.mercedes-benz.com/app/pricetags',
    },
    {
      title: 'DIGITAL_SIGNATURE',
      icon: 'digital_signature.svg',
      url: 'https://esigning.i.mercedes-benz.com/rlp/view/dashboard/dashboard.xhtml',
    },
  ];

  constructor(
    private readonly logger: NGXLogger,
    private readonly userService: UserService,
    private readonly orgaentitiesService: OrgaentitiesService,
  ) { }

  /**
   * Get static links which the user has the permissions to see.
   *
   * @returns List of static links
   */
  public async getPermittedStaticLinks(): Promise<StaticLinkWithParsedUrl[]> {
    const idToken = await this.userService.getIdToken();
    const user = await this.userService.getCurrentUser();
    if (!user) {
      this.logger.error('Could not get user', idToken, user);
      throw new Error('No user found');
    }

    const staticLinks: StaticLinkWithParsedUrl[] = [];

    // Add static links to UI array, checking the required rights.
    for (const staticLink of this.STATIC_LINKS) {
      // eslint-disable-next-line no-await-in-loop
      if (!staticLink.requiredRight || await this.userService.hasRight(staticLink.requiredRight)) {
        staticLinks.push({
          ...staticLink,
          // If the URL is provided via function, replace the URL with the actual string value.
          url: typeof staticLink.url === 'function' ? staticLink.url(user.country as CountryCode | undefined) : staticLink.url,
        });
      }
    }

    return staticLinks;
  }

  /**
   * Generate URL for Product Information Platform / MAR20X Knowledge Hub and open it in a new tab.
   */
  public async openProductInformationPlatformUrl(): Promise<void> {
    try {
      const user = await this.userService.getCurrentUser();
      if (!user) {
        this.logger.error('Could not get user', user);
        throw new Error('No user found');
      }

      // Determine the orgaentity at which the user that the PIP/WKP read rights.
      const pipRole = user.roles.find((role) => role.rights.includes(RoleRight.WEBKNOWLEDGE_READ));
      if (!pipRole) {
        throw Error('No MAR20X Knowledge Hub role assigned');
      }

      // Use the first orgaentity. (If there are more, the behavior is not defined.)
      const orgaentityId = pipRole.orgaentities[0].id;
      const orgaentity = await this.orgaentitiesService.getOrgaentity(orgaentityId);
      const baseLink = 'https://suite.newroom-connect.com/mar20x/signin?code=PIP';

      if (!orgaentity.level) {
        throw new Error('Invalid node for the MAR20X Knowledge Hub role');
      }

      let url = '';
      if (orgaentity.level === OrgaentityLevel.VS1.toString() || orgaentity.level === OrgaentityLevel.VS2.toString()) {
        url = `${baseLink}_${orgaentity.name.replace(/\s+/g, '')}_${orgaentity.level}`;
      } else if (orgaentity.level === OrgaentityLevel.VS3.toString()) {
        const ancestors = await this.orgaentitiesService.getAncestorNodes(orgaentityId);
        const vs2Node = ancestors.find((ancestor) => ancestor.level === OrgaentityLevel.VS2);
        if (!vs2Node) {
          throw new Error('No VS2 node found in ancestors');
        }

        url = `${baseLink}_${vs2Node.name.replace(/\s+/g, '')}_${orgaentity.level}`;
      }

      if (!url) {
        throw new Error('MAR20X Knowledge Hub URL could not be generated');
      }

      window.open(url, '_blank', 'noopener,noreferrer');
    } catch (error) {
      this.logger.error('Failed to generate URL for Product Information Platform', error);
      throw error;
    }
  }
}
